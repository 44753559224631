@import '../../../../scss/theme-bootstrap';

@if $cr20 == true {
  .site-my-mac {
    @include swap_direction(padding, 0 16.5%);
    height: $site-header-height;
    vertical-align: top;
    @include breakpoint($bp--largest-up) {
      display: block;
      padding: 0;
      height: 32px;
      background-color: $color--black;
    }
    &.active {
      background: $color--white;
      color: $color--black;
    }
    a {
      text-transform: none;
    }
    &__link {
      @include swap_direction(padding, 18px 0 0 0);
      @include heading-mono;
      @include unset-underline-links();
      display: inline;
      height: $site-header-height;
      line-height: normal;
      text-align: #{$ldirection};
      text-transform: uppercase;
      white-space: nowrap;
      @if $fonts_update {
        font-family: $helvetica-font;
        font-size: 11px;
      } @else {
        font-size: 10px;
      }
      @include breakpoint($bp--largest-up) {
        font-size: 17px;
        height: 32px;
        margin: 0 7px;
        padding: 0;
      }
      .gnav-util-trigger:checked ~ & {
        background: $color--white;
      }
      &-icon {
        display: none;
        @include breakpoint($bp--largest-up) {
          display: inline;
          color: $color--white;
          line-height: 32px;
          width: 18px;
          text-align: center;
        }
        .gnav-util-trigger:checked ~ .site-my-mac__link &,
        .active & {
          color: $color--black;
        }
      }
      &-header {
        display: block;
        @include breakpoint($bp--largest-up) {
          display: none;
          color: $color--white;
        }
      }
      .viewcart &,
      .checkout & {
        display: none;
        @include breakpoint($bp--largest-up) {
          display: inline-block;
        }
      }
      br {
        display: block;
        @include breakpoint($bp--largest-up) {
          display: none;
        }
      }
      .first-name {
        display: none;
        white-space: normal;
        @include breakpoint($bp--largest-up) {
          display: block;
        }
      }
    }
    &__link-label {
      cursor: pointer;
    }
    &__contents {
      @include body-mono();
      background: $color--white;
      color: $color--black;
      visibility: hidden;
      display: none;
      opacity: 0;
      width: 322px;
      position: absolute;
      #{$rdirection}: 0;
      text-align: #{$ldirection};
      top: auto;
      z-index: calc(#{$gnav-fixed-z-index} + 1);
      max-height: 95vh;
      overflow-y: auto;
      .gnav-util-trigger:checked ~ &,
      .site-my-mac.active & {
        display: block;
        opacity: 1;
        visibility: visible;
      }
      @if $payment_info_collapse {
        .active & {
          display: none;
        }
      }
      a:not(.btn) {
        @include set-link-colors();
      }
      &-header {
        @include clearfix();
        @include swap_direction(padding, 15px 15px 5px 15px);
        line-height: 30px;
      }
      &-close {
        @include unset-underline-links();
        float: #{$rdirection};
        font-size: 23px;
        i {
          vertical-align: top;
        }
      }
      &-title {
        @include h5();
        @include swap_direction(margin, 0);
        line-height: 1;
        @if $fonts_update {
          @include h4();
          @include breakpoint($bp--largest-up) {
            font-size: $h4-new-size;
          }
        } @else {
          @include h5();
        }
        &__alt {
          display: none;
        }
      }
      &-signin {
        @include swap_direction(padding, 0 15px 15px 15px);
        .error {
          text-transform: uppercase;
        }
        a:not(.btn) {
          @include set-underline-links();
        }
        .registration,
        .form-item,
        .password-request {
          @include swap_direction(margin, 0 0 15px 0);
        }
        .password-request {
          text-align: #{$rdirection};
        }
      }
      &-submit {
        @include btn;
        @include btn--full;
      }
      &-signed_in {
        display: none;
        a:not(.btn) {
          @include set-underline-links();
        }
      }
      &-account {
        @include swap_direction(padding, 0 15px 10px 15px);
        li {
          @include swap_direction(margin, 0 0 5px 0);
          display: block;
        }
      }
      &-footer {
        @include swap_direction(padding, 15px);
        border-top: 1px solid $color--gray--lighter;
        position: relative;
        &-title {
          @include h5();
          line-height: 1;
        }
        &.has-link {
          .site-my-mac__contents-footer-title {
            @include swap_direction(margin, 0 0 45px 0);
          }
          &:hover {
            @include reverse;
            border-top-color: $color--black;
          }
        }
        &-link {
          bottom: 15px;
          position: absolute;
        }
        &__loyalty {
          &.has-link {
            .site-my-mac__contents-footer-title {
              @include h6();
              @include swap_direction(margin, 0 0 15px 0);
            }
            .site-my-mac__contents-footer-copy {
              @include swap_direction(margin, 0 0 60px 0);
            }
          }
        }
      }
    }
    &__contents-footer__loyalty {
      .site-my-mac__contents-footer-link {
        position: relative;
        &::after {
          content: '\a';
          white-space: pre;
        }
      }
    }
  }
}
